import React from "react";
import Reveal from "react-reveal/Reveal";

const SnapserveSuite = () => {
  return (
    <section className="payment_clients_area">
      <div className="clients_bg_shape_top"></div>
      <div className="clients_bg_shape_right"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Reveal effect="fadeInLeft" duration={500}>
              <div className="payment_features_content pr_70">
                <div className="icon">
                  <img
                    className="img_shape"
                    src={require("../img/home9/icon_shape.png")}
                    alt=""
                  />
                  <img
                    className="icon_img"
                    src={require("../img/home9/icon2.png")}
                    alt=""
                  />
                </div>
                <h2>SnapServe Suite</h2>
                <p>
                  We offer a suite of mobile apps for Reports, Kitchen Display,
                  Customer Pad and the main SnapServe POS app.
                </p>
                <a
                  href="features"
                  className="btn_hover agency_banner_btn pay_btn"
                >
                  Learn more
                </a>
              </div>
            </Reveal>
          </div>
          <div className="col-lg-6">
            <div className="payment_clients_inner">
              <Reveal effect="fadeInLeft" duration={500}>
                <div className="clients_item one">
                  <img src={require("../img/home9/snapserve.svg")} alt="" />
                </div>
                <div className="clients_item two">
                  <img src={require("../img/home9/reports.png")} alt="" />
                </div>
                <div className="clients_item three">
                  <img
                    src={require("../img/home9/kitchen_display.png")}
                    alt=""
                  />
                </div>
                <div className="clients_item four">
                  <img src={require("../img/home9/logo4.png")} alt="" />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SnapserveSuite;
