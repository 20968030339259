import React, { Component } from "react";
import DownloadItems from "./Downloads/DownloadItems";

const DownloasZone = props => {
  const { DownloadData } = props;
  return (
    <section className="prototype_service_info">
      <div className="symbols-pulse active">
        <div className="pulse-1"></div>
        <div className="pulse-2"></div>
        <div className="pulse-3"></div>
        <div className="pulse-4"></div>
        <div className="pulse-x"></div>
      </div>
      <div className="container">
        <h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb_90">
          Find the latest update of our SnapServe Suite.
        </h2>
        <div className="row p_service_info">
          {DownloadData.DownloadApps.map(item => {
            return (
              <DownloadItems
                AppTitle={item.AppTitle}
                AppDescription={item.AppDescription}
                AppIcon={item.AppIcon}
                rclass={item.rclass}
                iclass={item.iclass}
                key={item.id}
                link={item.link}
                version={item.version}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default DownloasZone;
