import React, { Component } from "react";
import Slider from "react-slick";

class Pserviceslider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 800,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "100px",
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "100px"
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: "0px"
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: "0px"
          }
        }
      ]
    };
    return (
      <Slider className="service_carousel" {...settings}>
        <div className="service_item">
          <div className="icon s_icon_one">
            <i className="ti-pie-chart"></i>
          </div>
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Reporting &<br /> analytics
          </h4>
          <p>
            Access and analyse every transaction, get in-depth reports not just
            for sales but for all your enterprise’s operations through an
            intuitive and organized interface.
          </p>
          {/* <img
            className="float-right"
            src={require("../../../img/home2/undraw.png")}
            alt=""
          /> */}
        </div>
        <div className="service_item">
          <div className="icon s_icon_two">
            <i className="ti-stats-up"></i>
          </div>
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Insights
          </h4>
          <p>
            Get real time meaningful insights through our simple and
            comprehensive KPI reports. Track your performance and improve.
          </p>
          {/* <img
            className="float-right"
            src={require("../../../img/home2/inbox.png")}
            alt=""
          /> */}
        </div>
        <div className="service_item">
          <div className="icon s_icon_three">
            <i className="ti-gift"></i>
          </div>
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Gift Cards
          </h4>
          <p>
            Reward your customers and increase sales using our gift card
            programme. Create and offer gift cards at a snap.
          </p>
          {/* <img
            className="float-right"
            src={require("../../../img/home2/file.png")}
            alt=""
          /> */}
        </div>
        <div className="service_item">
          <div className="icon s_icon_four">
            <i className="ti-notepad"></i>
          </div>
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Labor cost <br />
            calculation
          </h4>
          <p>
            Calculate your labor cost accurately, plan your labor cost and get
            compliance alerts.
          </p>
          {/* <img
            className="float-right"
            src={require("../../../img/home2/report.png")}
            alt=""
          /> */}
        </div>
        <div className="service_item">
          <div className="icon s_icon_one">
            <i className="ti-home"></i>
          </div>
          <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">
            Store Management
          </h4>
          <p>
            Manage and track your inventory in real-time. Get low-stock warnings
            and replenish your stock on time. Map your catalog items to your
            inventory items and ingredients using our easy to use recipes
            module.
          </p>
          {/* <img
            className="float-right"
            src={require("../../../img/home2/undraw.png")}
            alt=""
          /> */}
        </div>
      </Slider>
    );
  }
}
export default Pserviceslider;
