import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import HomeBanner from "../components/Banner/HomeBanner";
import HomeFeatures from "../components/Features/HomeFeatures";
import HomeService from "../components/Service/HomeService";
import ServiceData from "../components/Service/ServiceData";
import SnapserveSuite from "../components/SnapserveSuite";
import PaymentTestimonial from "../components/Testimonial/PaymentTestimonial";
import FooterMain from "../components/Footer/FooterMain";
import FooterData from "../components/Footer/FooterData";

const HomePage = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" />
      <HomeBanner />
      <HomeFeatures />
      <HomeService ServiceData={ServiceData} />
      <SnapserveSuite />
      <FooterMain FooterData={FooterData} />
    </div>
  );
};
export default HomePage;
