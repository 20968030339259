import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Contacts from "../components/Contacts";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterMain from "../components/Footer/FooterMain";

import FooterData from "../components/Footer/FooterData";

const About = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Contact Us"
        Pdescription="Get in touch and arrange an appointment with one of our expert consultants."
      />
      <Contacts />
      <FooterMain FooterData={FooterData} />
    </div>
  );
};
export default About;
