const DownloadData = {
  DownloadApps: [
    {
      id: "1",
      AppTitle: "SnapServe POS",
      AppDescription:
        "This app is used by waiters or cashiers to create orders",
      AppIcon: "panel",
      rclass: "pr_70",
      iclass: "icon_one",
      link: "/packages/snapserve.apk",
      version: "1.20"
    },
    {
      id: "2",
      AppTitle: "SnapServe Reports",
      AppDescription:
        "'Reports' is used by Store Managers to monitor their revenue based on date criteria.",
      AppIcon: "layout-grid2",
      rclass: "pl_50 pr_20",
      iclass: "icon_two",
      link: "/packages/reports.apk",
      version: "1.0"
    },
    {
      id: "3",
      AppTitle: "Kitchen Display",
      AppDescription:
        "Install this on your Kitchen Display for showing your Orders in your kitchen",
      AppIcon: "fullscreen",
      rclass: "pl_70",
      iclass: "icon_three",
      link: "/packages/kitchen_display.apk",
      version: "1.0"
    },
    {
      id: "4",
      AppTitle: "Customer Pad",
      AppDescription:
        "For stores that accept Credit Cards as a payment method.",
      AppIcon: "vector",
      rclass: "pr_70",
      iclass: "icon_four",
      link: "/packages/customer_pad.apk",
      version: "1.13"
    }
  ]
};
export default DownloadData;
