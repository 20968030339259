import React, { Component } from "react";
import Reveal from "react-reveal";
const DownloadItems = props => {
  const {
    AppTitle,
    AppDescription,
    AppIcon,
    rclass,
    iclass,
    link,
    version
  } = props;
  return (
    <div className="col-lg-4 col-sm-6">
      <Reveal bottom cascade>
        <div className={`p_service_item ${rclass}`}>
          <div className={`icon ${iclass}`}>
            <i className={`ti-${AppIcon}`}></i>
          </div>
          <h5 className="f_600 f_p t_color3">
            {AppTitle}{" "}
            <p className="f_400" style={{ fontSize: "12px" }}>
              Version {version}
            </p>
          </h5>
          <p className="f_400">{AppDescription}</p>
          <a href={link} className="btn_three">
            Download
          </a>
        </div>
      </Reveal>
    </div>
  );
};
export default DownloadItems;
