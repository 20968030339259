const ServiceData = {
  STitle: "SnapServe - Simply powerful",
  Service: [
    {
      id: 1,
      shape: "icon_shape1.png",
      icon: "ti-panel",
      text: "24/7 Technical Support",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    },
    {
      id: 2,
      shape: "icon_shape2.png",
      icon: "ti-layout-grid2",
      text: "Training your staff",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    },
    {
      id: 3,
      shape: "icon_shape3.png",
      icon: "ti-gallery",
      text: "Cloud Services",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    },
    {
      id: 4,
      shape: "icon_shape4.png",
      icon: "ti-fullscreen",
      text: "Pixel Precision",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    }
  ],
  agtext1: "Why I say old chap that is spiffing bodge, blag pardon.",
  agtext2: "Why I say old chap that is spiffing bodge, blag pardon.",
  agtext3: "Starkers dropped a clanger lurgy is cack excuse my French what.",
  pServiceTitle: "Our Services",
  pServiceDetails:
    "SnapServe offers a variety of services, helping you evolve your business",
  PaymentService: [
    {
      id: 1,
      icon: "headphone-alt",
      title: "24/7 Tehcnical Support",
      description:
        "An expert will guide you on troubleshooting whenever you wish"
    },
    {
      id: 2,
      icon: "briefcase",
      title: "Consulting",
      description:
        "Our consultants will suggest you the right modules you need for your setup"
    },
    {
      id: 3,
      icon: "blackboard",
      title: "Training your staff",
      description: "Your staff will receive a training on our platform"
    },
    {
      id: 4,
      icon: "harddrives",
      title: "Technical Infrastracture setup",
      description:
        "SnapServe is so easy to set it up to your business with a few clicks"
    },
    {
      id: 5,
      icon: "bar-chart",
      title: "Business Analysis",
      description: "Use our Reporting system to get meaningfull insights"
    }
  ],
  HRService: [
    {
      id: "1",
      HRtitles: "Support",
      HRdescription:
        "We provide 24x7 customer support as well as health monitoring. With our built-in health monitoring tools we proactively monitor your entire infrastructure and get alerts before you even know it",
      Hicon: "headphone-alt",
      rclass: "pr_70",
      iclass: "icon_one"
    },
    {
      id: "2",
      HRtitles: "Customer engagement ",
      HRdescription:
        "Optimize your discount policy with our flexible discount management system. Provide item level, order level discounts and keep your customers happy. Design a loyalty scheme that’s right for your business and never lose a customer.",
      Hicon: "user",
      rclass: "pl_50 pr_20",
      iclass: "icon_two"
    },
    {
      id: "3",
      HRtitles: "Workflow management",
      HRdescription:
        "Evolve your business painlessly. We can adapt to your needs over time. We support dine-in, take out, catering and delivery order types, each one with it’s own customizable user flow.  ",
      Hicon: "stats-up",
      rclass: "pl_70",
      iclass: "icon_three"
    },
    {
      id: "4",
      HRtitles: "Team management",
      HRdescription:
        "Manage your team easily and smoothly. Keep your employees labor data organized -- paperless. Organize employee work shifts in advance according to your workload.  ",
      Hicon: "timer",
      rclass: "pr_70",
      iclass: "icon_four"
    },
    {
      id: "6",
      HRtitles: "Inventory",
      HRdescription:
        "Manage and track your inventory in real-time. Get low-stock warnings and replenish your stock on time. Map your catalog items to your inventory items and ingredients using our easy to use recipes module.",
      Hicon: "archive",
      rclass: "pl_70",
      iclass: "icon_six"
    },

    {
      id: "5",
      HRtitles: "API Integration",
      HRdescription:
        "Manage and track your inventory in real-time. Get low-stock warnings and replenish your stock on time. Map your catalog items to your inventory items and ingredients using our easy to use recipes module.",
      Hicon: "link",
      rclass: "pl_50 pr_20",
      iclass: "icon_five"
    }
  ],
  rpost: [
    {
      id: "01",
      image: "blog-grid/post_1.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 1, 2019"
    },
    {
      id: "02",
      image: "blog-grid/post_2.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 10, 2019"
    },
    {
      id: "03",
      image: "blog-grid/post_3.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "jan 10, 2019"
    },
    {
      id: "04",
      image: "blog-grid/post_4.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 1, 2019"
    }
  ],
  Blist: [
    {
      id: "01",
      image: "blog-grid/blog_list1.jpg",
      btitle: "Why I say old chap that is, spiffing jolly good.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "10",
      Month: "March"
    },
    {
      id: "02",
      image: "blog-grid/blog_list2.jpg",
      btitle: "Why I say old chap that is, spiffing jolly good.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "10",
      Month: "June"
    },
    {
      id: "03",
      image: "blog-grid/blog_list3.jpg",
      btitle: "Why I say old chap that is, spiffing jolly good.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "10",
      Month: "March"
    },
    {
      id: "04",
      image: "blog-grid/blog_list4.jpg",
      btitle: "Bloke cracking goal the full monty get stuffed mate posh.",
      bdescription:
        "Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.",
      pdate: "01",
      Month: "July"
    }
  ],
  work: [
    {
      id: "01",
      icon: "ti-settings one",
      title: "Premium plugins",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    },
    {
      id: "02",
      icon: "ti-heart-broken two",
      title: "Premium plugins",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    },
    {
      id: "03",
      icon: "ti-target  three",
      title: "Live page builder",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    }
  ]
};
export default ServiceData;
